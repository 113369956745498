@import "../../globals.scss";

.media {
    @include content-wrapper;
    
    padding-bottom: 2rem;
    @include small-screen {
        padding-bottom: 1rem;
    }

    $cutoff: 1000px;

    &__content-wrapper {
        padding: 4rem 0 0;
    }

    &__heading {
        font-size: $font-size-h2;
        font-weight: 300;
        text-align: center;
        margin-bottom: 2rem;
        outline: none;
        color: white;

        &-background {
            padding: 0 3rem;
            background-image: url("../../images/brush-stroke-banner-7-red.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position-x: center;
        }
    }

    &__album-section {
        display: flex;
        justify-content: space-around;

        @include below-width($cutoff) {
            flex-direction: column;
        }
    }

    &__featured-album-wrapper,
    &__secondary-albums-wrapper {
        width: 50%;
        margin: 0 auto;
        padding: 0 1rem;

        @include below-width($cutoff) {
            width: 100%;
            margin-top: 2rem;
        }
    }

    &__album {
        height: 20rem;
        width: 20rem;
        max-width: 100%;
        margin: 1rem;
        background-color: $secondary-background-color;

        &-large {
            height: 43rem;
            width: 35rem;
            margin: 0 auto;
            background-color: $secondary-background-color;

            @include below-width($cutoff) {
                max-width: 100%;
            }
        }

        &-iframe {
            border: 0;
            height: 100%;
            width: 100%;
        }
    }

    &__video-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 4rem;
    }

    &__video {
        width: 45%;
        height: 30rem;
        margin: 2rem auto;
        background-color: $secondary-background-color;

        @include below-width($cutoff) {
            width: 100%;
            height: 20rem;
        }

        &-iframe {
            border: 0;
            height: 100%;
            width: 100%;
        }
    }
}
