@import "../../globals.scss";

.parallax-window {
  height: 30vh;
  position: relative;
  transform-style: preserve-3d;
  background-color: rgba(0, 0, 0, 0.3);

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate3d(0, -20%, -300px) scale(2.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-image: url("../../images/hero-bw.jpeg");
    z-index: 0;
    height: $hero-image-height;
  }
}
