@import "../../globals.scss";

.contact {
  &__page-wrapper {
    display: flex;
    padding: 2rem 4rem;
    flex-grow: 2;

    @include small-screen {
      padding: 1rem 2rem;
    }

    @include small-screen {
      flex-direction: column-reverse;
    }
  }

  &__heading {
    font-size: $font-size-h2;
    margin: 2rem 0;
    outline: none;
    font-weight: 300;
    color: white;

    &-background {
      padding: 0 3rem;
      background-image: url("../../images/brush-stroke-banner-7-red.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position-x: center;
    }

    @include small-screen {
      font-size: $font-size-h3;
    }
  }

  &__sub-heading {
    font-size: $font-size-h4;
    margin-bottom: 2rem;

    @include small-screen {
      font-size: $font-size-h5;
    }
  }

  &__services {
    font-size: 2rem;
    margin-bottom: 5rem;
  }

  &__photo-wrapper {
    width: 35%;
    min-width: 20rem;
    max-height: 50vh;

    @include small-screen {
      display: none;
    }
  }

  &__photo {
    width: 100%;
    max-height: 50vh;
    margin-top: 2rem;
    object-fit: cover;

    @include small-screen {
      padding-top: 0;
    }
  }

  &__form-wrapper {
    margin-bottom: 4rem;
    margin-left: 5vw;
    flex-shrink: 2;

    @include small-screen {
      width: 100%;
    }
  }
}
