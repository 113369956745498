@import "../../globals.scss";

.form {
    padding-top: 2rem;
    max-width: 60rem;

    textarea {
        resize: none;
        font-family: $sans-serif;
    }

    &__heading {
        padding-bottom: 2rem;
        font-size: $font-size-h3;
        text-align: center;

        @include small-screen {
            font-size: $font-size-h5;
        }
    }

    &__form-element {
        padding-bottom: 2rem;
    }

    &__label {
        font-size: $font-size-large;
        font-family: $serif;
        font-weight: normal;
        display: block;
        padding-bottom: 1rem;
    }

    &__input {
        width: 100%;
        box-sizing: border-box;
        font-size: $font-size-h5;
        padding: 0.5rem;
    }

    &__error {
        color: $background-red;
        padding-top: 0.5rem;
    }
    
    &__api-error-msg {
        font-size: $font-size-h5;
    }

    &__submit-wrapper {
        display: flex;
    }

    &__submit {
        font-size: $font-size-h5;
        background-color: $secondary-background-color;
        color: white;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 1rem 2rem;
        @include transition(0.2s all);

        &:hover {
            background-color: $primary-background-color;
            color: $text-color;
            cursor: pointer;
        }
    }

    &__captcha-terms {
        padding-top: 0.5rem;
    }
}
