@import "../../globals.scss";

.bio {
    @include content-wrapper;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @include small-screen {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__flex {
        display: flex;

        @include small-screen {
            flex-direction: column-reverse;
        }
    }

    &__album-column {
        text-align: center;
    }

    &__album-wrapper {
        $album-width: 30rem;
        width: $album-width;
        max-width: 100%;
        height: $album-width;
        margin: 1.9rem 8rem 0 auto;
        background-color: $background-red;
    }

    &__album-caption {
        margin-top: 1rem;
    }

    &__bio-column {
        font-family: $serif;
        max-width: 50ch;
        margin: 0 auto 3rem;
        padding: 0 4rem;

        @include small-screen {
            margin: 0 0 4rem;
            padding: 0 1rem;
        }

        p {
            font-size: $font-size-h5;
            margin-top: 3rem;
            text-align: justify;

            @include small-screen {
                font-size: $font-size-large;
            }
        }
    }

    &__bio-heading {
        font-size: $font-size-h1;
        color: $background-red;
        margin-bottom: 1rem;
        text-align: left;
        display: inline;
        font-weight: 400;
        font-family: $sans-serif;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position-x: left;

        @include small-screen {
            font-size: $font-size-h4;
        }
    }
}
