@import "../../globals.scss";

.footer {
    text-align: center;
    padding: 1rem 0;
    background-color: $primary-background-color;
    color: white;

    &__brush {
        margin: 0 auto;
        padding: 1rem 6rem;
        display: inline-block;
        background-image: url("../../images/brush-stroke-banner-7-red.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position-x: center;

        @include small-screen {
            padding: 1rem 5rem;
        }
    }

    &__text {
        margin: 0.5rem auto;
        font-size: 1.3rem;
    }

    &__disclaimer {
        a {
            @include transition(color 0.3s);
            color: white;

            &:hover {
                color: gray;
            }
        }
        font-size: 10px;
    }
}
