@import "../../globals.scss";

.spinner {
  padding: 0 1rem;
  
  &__icon {
    border: 4px solid $background-red; 
    border-top: 4px solid white; /* Blue */
    border-radius: 50%;
    min-width: 30px;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 4px;
  }
}

@keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
