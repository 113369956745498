@import "../../globals.scss";

.hero {
  position: relative;
  transform-style: preserve-3d;
  height: $hero-image-height;
  background-color: rgba(0, 0, 0, 0.3);
  
  &__background-layer {
    transform: translateZ(-300px) scale(2.5);
    position: absolute;
    top: -5vh;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 0;
    background-image: url("../../images/hero-bw.jpeg");
    @include transition(background-image 0.2s ease-in-out);

    @include small-screen {
      background-position-x: 60%;
    }
  }

  &__hero-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: 4vh;
    box-sizing: border-box;

    @include small-screen {
      justify-content: flex-end;
      padding-bottom: 12vh;
    }
  }

  &__hero-title {
    color: white;
    text-align: left;
    font-size: calc(3.5rem + 2vw);
    font-weight: 300;
    padding-left: 4rem;

    @include small-screen {
      font-size: calc(0.5rem + 8vw);
      padding-left: 0;
      text-align: center;
    }
  }
}
