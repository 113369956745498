@import "../../globals.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem 4rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  background-color: $primary-background-color;
  z-index: 2;

  &__heading {
    padding: 0;
    margin: 0;
    font-size: 2.6rem;
    font-weight: 300;
  }

  &__home {
    text-decoration: none;
    outline: none;
    @include transition(all 0.2s);

    &:hover,
    &:focus {
      @include scale(1.1);

      @include small-screen {
        @include scale(1);
      }
    }
  }

  &__links {
    display: flex;
  }

  &__paint-stroke-wrapper {
    position: relative;
    margin-left: 3rem;

    &:hover {
      .navbar__paint-stroke-cover {
        width: 0%;
        left: initial;
        right: 0;

        @include small-screen {
          width: 100%;
        }
      }
    }
  }

  &__paint-stroke-cover {
    z-index: 2;
    background-color: $primary-background-color;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    @include transition(width 0.3s);
  }

  &__paint-stroke {
    z-index: 1;
    background-image: url("../../images/brush-stroke-banner-7-red.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__link {
    position: relative;
    z-index: 3;
    color: $text-color;
    font-size: $font-size-large;
    font-family: $sans-serif;
    text-decoration: none;
    @include transition(color 0.2s);
    padding: 0.5rem 2rem;
    display: block;

    &:focus {
      outline: 1px solid black;
    }

    &:hover {
      color: white;

      @include small-screen {
        color: inherit;
      }
    }
  }

  .icon {
    color: black;
    display: none;
    padding: 2rem 2rem 1rem;
  }

  @include small-screen {
    padding: 1rem 2rem;

    &__heading {
      font-size: $font-size-h6;
    }

    &__links {
      flex-direction: column;
      text-align: right;
    }

    &__link {
      margin: 0 -2rem 0 0;
      padding: 1rem;
      font-size: $font-size-small;

      &.closed {
        opacity: 0%;
        display: none;
      }

      &.open {
        @include animation("fadeInFromNone .2s");
      }
    }

    .icon {
      display: block;
      font-size: 1.5rem;
      padding: 0;
      text-align: right;
    }
  }
}
