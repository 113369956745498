@import "../../globals.scss";

.calendar {
    @include content-wrapper;
    
    &__content-wrapper {
        min-height: 40rem;
        padding: 4rem 0;
    }

    &__heading {
        font-size: $font-size-h2;
        font-weight: 300;
        margin-bottom: 2rem;
        outline: none;
        color: white;

        &-background {
            padding: 0 3rem;
            background-image: url("../../images/brush-stroke-banner-7-red.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position-x: center;
        }
    }

    &__event {
        border-bottom: 1px solid black;
        width: 60%;
        margin: 2rem 0;
        padding: 1rem 1rem 3rem;
        display: flex;

        &:last-child {
            border-bottom: none;
            padding-bottom: 1rem;
        }

        @include small-screen {
            width: 100%;
            box-sizing: border-box;
        }

        &-heading {
            font-size: $font-size-h5;
            font-weight: 300;
        }

        &-date-time,
        &-venue {
            font-size: $font-size-h6;
            font-family: $serif;
        }

        &-date-time {
            @include small-screen {
                font-size: $font-size-large;
                padding-top: 1rem;
            }
        }

        &-title-wrapper {
            flex-grow: 2;
        }

        &-venue-wrapper {
            text-align: right;
        }

        &-address {
            font-weight: 300;

            @include small-screen {
                font-size: $font-size-h6;
            }
        }
    }
}
