$content-width: 120rem;
$navbar-height: 5rem;

$font-size-h1: 5rem;
$font-size-h2: 4rem;
$font-size-h3: 3rem;
$font-size-h4: 2.5rem;
$font-size-h5: 2rem;
$font-size-h6: 1.7rem;
$font-size-large: 1.5rem;
$font-size-normal: 1.3rem;
$font-size-small: 1.1rem;

$sans-serif: 'Quicksand', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
$serif: 'Martel', 'Times New Roman', Times, serif;

$secondary-background-color: #333132;
$primary-background-color: #fffefd;
$background-red: #ba5d40;
$background-orange: #AC634A;
$background-yellow: #b48031;
$background-green: #4f825e;
$background-blue: #647c9a;
$text-color: #32334D;
$link-color: $text-color;
$link-hover-color: darkgray;

$hero-image-height: 80vh;

@mixin small-screen {
    @media (max-width: 700px) {
        @content;
    }
}

@mixin content-wrapper {
    margin: 0 auto;
    padding: 0 4rem;
    width: 100%;
    max-width: $content-width;
    box-sizing: border-box;

    @include small-screen {
      padding: 0 2rem;
    }
}

@mixin below-width($width) {
    @media (max-width: $width) {
        @content;
    }
}

// Cross-browser animation mixins from https://joshbroton.com/quick-fix-sass-mixins-for-css-keyframe-animations/
@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin scale($factor) {
    -webkit-transform: scale($factor);
    -moz-transform: scale($factor);
    -ms-transform: scale($factor);
    -o-transform: scale($factor);
    transform: scale($factor);
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@include keyframes(fadeInFromNone) {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}