@import "../../globals.scss";

.lessons {
  .flex {
    background-color: $primary-background-color;
  }

  &__page-wrapper {
    display: flex;
    margin: 0 auto;
    padding: 2rem 4rem;

    @include small-screen {
      padding: 1rem 2rem;
    }

    @include small-screen {
      flex-direction: column-reverse;
    }
  }

  &__form-wrapper {
    margin-left: 5vw;
    margin-bottom: 5rem;
    flex-shrink: 2;

    @include small-screen {
      margin-left: 0;
    }
  }

  &__heading {
    font-size: $font-size-h2;
    margin-bottom: 2rem;
    outline: none;
    font-weight: 300;
    color: white;

    &-background {
      padding: 0 3rem;
      background-image: url("../../images/brush-stroke-banner-7-red.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position-x: center;
    }

    @include small-screen {
      font-size: $font-size-h3;
    }
  }

  &__para {
    font-size: $font-size-h6;
    font-family: $serif;

    @include small-screen {
      font-size: $font-size-large;
    }

    &-rates {
      margin: 4rem 0;

      @include small-screen {
        margin: 3rem 0;
        font-size: $font-size-h5;
      }
    }
  }

  &__flex-columns {
    display: flex;

    @include small-screen {
      flex-direction: column;
    }
  }

  &__column {
    width: 50%;

    @include small-screen {
      width: 100%;
    }

    h3 {
      font-size: $font-size-h5;

      @include small-screen {
        font-size: $font-size-h6;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      display: block;
    }

    li {
      font-size: $font-size-large;
      padding-bottom: 1rem;
      margin: 1rem 6rem 0 2rem;
      border-bottom: 1px solid black;
      font-family: $serif;
      
      &:last-child {
        border-bottom: none;
      }
      
      @include small-screen {
        font-size: $font-size-normal;
      }
    }

    &:last-child {
      margin-left: 4rem;

      @include small-screen {
        margin-left: 0;
        margin-top: 2rem;
      }
    }
  }

  &__photo-wrapper {
    width: 50%;
    min-width: 20rem;
    max-height: 50vh;

    @include small-screen {
      display: none;
    }
  }

  &__photo {
    width: 100%;
    padding-top: 7.3rem;
    max-height: 50vh;
    object-fit: cover;

    @include small-screen {
      padding-top: 0;
    }
  }
}
